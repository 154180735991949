import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import covidpic from "../../images/COVID.png";
import OrderComp from "./paymentComp/OrderComp";
import "./payment.css";
import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
// import NewAddress from "./paymentComp/NewAddress";
import SavedAddress from "./paymentComp/SavedAddress.jsx";
// import { faScaleUnbalancedFlip } from "@fortawesome/free-solid-svg-icons";
import razorPayLogo from "./../../images/razorparLogo.png";
import { API } from "../../backend";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { DisabledByDefault } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { cartEmpty } from '../../redux/actions/cartAction';
import Loading from "../commonComponents/LoadingScreen/Loading";
import AddBillingAddress from "./paymentComp/AddBillingAddress";
import checkBox from "../../images/icons/checkBox.svg";
import plusIcon from "../../images/icons/plusBgIcon.svg";
import closeIcon from "../../images/icons/closeIcon.svg";
import backIcon from "../../images/userDashboard/trackOrderImages/back.png";
import {
  // editUserSamegAddress,
  updateUserAddressAndWishlist,
} from "../../redux/actions/userAction";
import { AddressPopup } from "../commonComponents/popUp/PopUp";

const Payment = () => {
  const [savedAdd, setSavedAdd] = useState(true);
  // const [newAddClick, setNewAddClick] = useState(false);
  const [billingAdd, setBillingAdd] = useState(true);
  const [selectedAddToggle, setSelectedAddToggle] = useState(false);
  const [orderClick, setOrderClick] = useState(false);
  // const [paymentClick, setPaymentClick] = useState(false);
  const [deliveryAdd, setDeliveryAdd] = useState(null);
  const [addToggle, setAddToggle] = useState(false);
  const state = useSelector((state) => state.cart);
  console.log(state, "state");
  let userDetail = useSelector((state) => state.user.userDetail);
  const [confirmDelivNo, setConfirmDelivNo] = useState(Number);
  // const dispatch = useDispatch();
  const [a, seta] = useState(false);

  const isDisabled = deliveryAdd === null || state.cart <= 0;

  // const handleChange = () => {};
  let defaultIndex;
  userDetail &&
    userDetail.address.forEach((e, i) => {
      if (e.default) {
        defaultIndex = i;
      }
    });
  console.log("defaultIndex, deliveryAdd =", defaultIndex, deliveryAdd);
  useEffect(() => {
    const topView = () => {
      window.scrollTo({ top: 0 });
    };
    topView();
  }, []);

  // console.log("deliveryAdd", deliveryAdd);
  console.log("userDetail", userDetail);
  const navigate = useNavigate();
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if (!localStorage.getItem("myuser")) {
      navigate("/");
    }
  }, [navigate]);

  // let totaldis = false;
  // let withQtydis = 0;
  let finalAmount = 0;

  let cartTotal = 0;
  let cartdata = [];
  if (state.cart.length !== 0) {
    cartdata = state.cart;
    // console.log(cartdata);

    cartdata.forEach((elem) => {
      // changed elem.total to total to enhance the functionality when user is not loggedin.
      let total = elem.total ? elem.total : elem.price * elem.quantity;
      let discountType = elem.discount_type
        ? elem.discount_type
        : elem.poster_details.discount_type;
      // total price after discount :
      cartTotal += Number(total);

      // total price before discount :
      if (discountType === 1) {
        let discountValue = elem.discountValue
          ? elem.discountValue
          : elem.poster_details.discountValue;
        finalAmount += Number(total) + Number(discountValue);
      } else if (discountType === 2) {
        let discountValue = elem.discountValue
          ? elem.discountValue
          : elem.poster_details.discountValue;
        finalAmount +=
          Number(total) +
          Number(parseFloat(total * (discountValue / 100)).toFixed(2));
      }
      //   if (elem.quantity >= 5) {
      //     withQtydis =
      //       withQtydis + (Number(total) - (Number(total) / 100) * 10);
      //   } else {
      //     withQtydis = Math.floor(withQtydis + Number(total));
      //     finalAmount = withQtydis;
      //     finalAmount = Math.ceil(withQtydis);
      //   }
    });
  }
  var deliveryCharge = 0;
  var discount = 0;
  if (cartTotal) {
    discount = cartTotal / 20;
    discount = Math.floor(discount);
    if (cartTotal - discount < 2000) {
      deliveryCharge = 50;
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // RazorPay Payment Code
  async function placeOrderHandler() {
     
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      );

      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }

      //prepare cart
      const cart = [];
      state.cart.forEach((ele) => {
           
         let data = {
          material_obj_id: ele.materialDimension._id,
          quantity: ele.quantity,
        };
        if (ele.poster_details) {
          data.poster_obj_id = ele.poster_details._id;
        }
        if (ele.pageDetail) {
          data.pageId = ele.pageDetail._id;
        }
        cart.push(data);
      });
      console.log(cart, 'DITTTTTTTTTTTTT');
      const token = JSON.parse(localStorage.getItem('myuser')).token;
      console.log("up to here successfull")
      let result = await fetch(`${API}orders/create_order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-access-token': token },
        body: JSON.stringify({
          cart_item: [...cart],
          delivery_address:
            userDetail.address[deliveryAdd] ||
            userDetail.address[defaultIndex] ||
            {},
          user_type: 1, //1 is for REGISTERED_USER
              // user_type: 2, // 2 is for  NEW_USER
        }),
      });

      result = await result.json();
      // console.log(result);

      // console.log('cart_item after fetch', result.cart_item);
      // console.log('delivery_address after fetch', result.delivery_address);
      if (!result) {
        alert('Server error. Are you online?');
        return;
      }
      console.log('result-----------------------------checking resust ', result);

      if (result.status === 200) {
        toast.success(`Your order is ${result.message} created`, {
          position: 'bottom-left',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        });
      } else {
        toast.error(result.message, {
          position: 'bottom-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        });
        return;
      }

      // return

      // Getting the order details back
      const { amount, order_id, currency, parentOrderId } = result.data;
      console.log('order id ==================', parentOrderId, amount); //This amount is actual amout paid by user

      const options = {
        // key: 'rzp_test_waNrqlrJMtHl9a', // Key ID for Test Mode 
        key: 'rzp_live_sHh59KhpUwzUmE', //Key ID for Live Mode 
        amount: amount,
        currency: currency,
        name: userDetail.name,
        description: 'Test Transaction',
        image: { razorPayLogo },
        order_id: parentOrderId,
        handler: async function (response) {
          const data = {
            orderCreationId: parentOrderId,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            confirmDelivNo: confirmDelivNo,
          };

          seta(true);
          // const result = await axios.post(`${API}orders/on_success_payment`, data);
          
          let result1 = await fetch(`${API}orders/on_success_payment`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': token },
            body: JSON.stringify(data),
          });

          result1 = await result1.json();

          console.log(result1, 'successfil order ............................');
          if (result1.status === 200) {
            dispatch(cartEmpty());
            seta(false);
            // toast.success(result1.message, {
            //   position: "bottom-left",
            //   autoClose: 1000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   closeButton: false,
            // });
            //
            navigate('/thankyou');
            //
          } else {
            toast.error(result1.message, {
              position: 'bottom-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false,
            });
            return;
          }
        },
        prefill: {
          name: userDetail.name,
          email: userDetail.emailid,
          contact: userDetail.phoneNumber,
        },
        notes: {
          address: 'no address',
        },
        theme: {
          color: '#61dafb',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
  }

  //=================================Instamojo Payment Code=============================================
  //Edit by Harshit

  // Coupon code
  const [displayCoupon, setDisplayCoupon] = useState(false);

  const [coupon, setCoupon] = useState(null);

  const [ehstrialCoupon, setEhstrialCoupon] = useState(null);

  const [ehs20, setEhs20] = useState(null);

  const [couponError, setCouponError] = useState(null);

  const handleApplyCoupon = () => {
    if (coupon === "EHSTRIAL" && cartTotal <= 800) {
      setEhstrialCoupon(100);
      setCouponError(null);
      // setCoupon(null);
    } else if (coupon === "EHSTRIAL" && cartTotal >= 800) {
      toast.warn("This coupon is only available under ₹800", {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });
      setCouponError("This coupon is only available under ₹800");
      // setCoupon(null);
      // setEhstrialCoupon(null);
    }
    if (coupon === "EHS20" && cartTotal >= 5000) {
      const calculate20Percent = (cartTotal * 20) / 100;
      setEhs20(calculate20Percent);
      setCouponError(null);
      // console.log(calculate20Percent);
      // setCoupon(null);
    } else if (coupon === "EHS20" && cartTotal <= 5000) {
      toast.warn("This coupon is only available above ₹5000", {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: true,
      });
      setCouponError("This coupon is only available above ₹5000");
      // setCoupon(null);
      // setEhs20(null);
    }
    if (coupon !== "EHSTRIAL" && coupon !== "EHS20") {
      toast.error("Invalid coupon code", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: true,
      });
      setCoupon(null);
      setEhs20(null);
      setEhstrialCoupon(null);
    }
  };

  // async function placeOrderHandler() {
  //   const curr_location = window.location.href;
  //   const updateUrl = curr_location.replace("/pay", ""); 

  //   //prepare cart
  //   const cart = [];
  //   state.cart.forEach((ele) => {
  //     let data = {
  //       material_obj_id: ele.materialDimension._id,
  //       quantity: ele.quantity,
  //     };
  //     if (ele.poster_details) {
  //       data.poster_obj_id = ele.poster_details._id;
  //     }
  //     if (ele.pageDetail) {
  //       data.pageId = ele.pageDetail._id;
  //     }
  //     cart.push(data);
  //   });
  //   console.log(cart, "DITTTTTTTTTTTTT");
  //   const token = JSON.parse(localStorage.getItem("myuser")).token;

  //   seta(true);
  //   let response = await fetch(`${API}orders/create_order`, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json", "x-access-token": token },
  //     body: JSON.stringify({
  //       cart_item: [...cart],
  //       delivery_address:
  //         userDetail.address[deliveryAdd] ||
  //         userDetail.address[defaultIndex] ||
  //         {},
  //       billing_address:
  //         userDetail.billing_address[deliveryAdd] ||
  //         userDetail.billing_address[defaultIndex] ||
  //         userDetail.address[deliveryAdd] ||
  //         userDetail.address[defaultIndex] ||
  //         userDetail.address[0] || 
  //         {},
  //       user_type: 1,
  //       current_url: updateUrl,
  //       ehsTrialCoupon: ehstrialCoupon,
  //       ehs20Coupon: ehs20,
  //     }),
  //   });

  //   let result = await response.json();

  //   if (!result) {
  //     alert("Server error. Are you online?");
  //     return;
  //   }
  //   console.log("result", result.data);

  //   if (result.status === 200) {
  //     seta(false);
  //     toast.success(`Your order is ${result.message} created`, {
  //       position: "bottom-left",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       closeButton: false,
  //     });
  //   } else {
  //     toast.error(result.message, {
  //       position: "bottom-center",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       closeButton: false, 
  //     });
  //     return;
  //   }
  //   seta(true);
  //   /*NOTE: I am not understand what mean of seta and what useful of 'a' is here */
  //   window.location.href = result.data.longUrl;
  // }

  // const newLocal = (
  //   <p id="mrp"> - &#8377;{parseFloat(finalAmount - cartTotal).toFixed(2)}</p>
  // );
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // Adding new address code

  const [defaultt, setdefaultt] = useState(false);
  // const [type, settype] = useState("");
  // const [toggle, setToggle] = useState(false);
  const [isDeliveryAddressSame, setIsDeliveryAddressSame] = useState(false);
  const dispatch = useDispatch();
  const [userAddress, setUserAddress] = useState({
    Area_Street: "",
    type: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
    name: "",
    phone: "",
    default: defaultt,
    isDeliveryAddressSame: isDeliveryAddressSame,
  });
  const handleNewAddChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserAddress({ ...userAddress, [name]: value });
    // console.log(userAddress)
  };

  console.log(userAddress, "USER++");

  const SaveAddress = () => {
    // console.log("hi", userAddress);

    if (
      userAddress.Area_Street !== "" &&
      userAddress.type !== "" &&
      userAddress.pincode !== "" &&
      userAddress.city !== "" &&
      userAddress.state !== "" &&
      userAddress.country !== "" &&
      userAddress.name !== "" &&
      userAddress.phone !== ""
    ) {
      dispatch(updateUserAddressAndWishlist(true, "", userAddress, "", "", ""));
      setUserAddress({});
      // setToggle(false);
      console.log(userDetail.address.length);
      setDeliveryAdd(userDetail.address.length);
      setSelectedAddToggle(true);
      setSavedAdd(false);
      setConfirmDelivNo(userDetail.phone)
      setOrderClick(true);
      setAddToggle(false);
    } else {
      // window.alert("Provide detail properly")
      toast.error("Provide all details and fill the checklist", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });
    }
  };

  const handleSameAddress = (e) => {
    // e.preventDefault();
    if (isDeliveryAddressSame) {
      setUserAddress({
        ...userAddress,
        Area_Street: userDetail?.billing_address[0]?.Area_Street,
        pincode: userDetail?.billing_address[0]?.pincode,
        city: userDetail?.billing_address[0]?.city,
        state: userDetail?.billing_address[0]?.state,
        country: "India",
        name: userDetail?.billing_address[0]?.name,
        phone: userDetail?.billing_address[0]?.phone,
      });
    }
  };

  console.log(isDeliveryAddressSame, "CHECK++");

  return a ? (
    <Loading />
  ) : (
    <div className="payment-container">
      <ToastContainer
        className=" toast__margin"
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Link
        to="/cartpage"
        className=" flex flex-row gap-3 items-center mb-3 no-underline hover:underline ml-2 max-w-[180px]"
      >
        <img src={backIcon} alt="" className="w-6" />
        <p className="mb-0 text-blue-500 font-semibold text-2xl">Go to cart</p>
      </Link>
      <h4 className="pay-heading">Payment</h4>
      <div className="pay-line"></div>
      <div className="payment-main">
        <div className="payement-left">
          <div className="flex md:flex-row flex-col gap-3 justify-between md:mx-[21px] mx-0">
            {/* billing address */}
            <div className="address-dropdown dropdownlist">
              <div className="list">
                <p className=" cursor-pointer hover:text-[#d9d9d9] text-white mb-0">
                  <span className="num">1</span> Billing Address
                </p>
                <span
                  className=" cursor-pointer"
                  onClick={() => {
                    setBillingAdd(!billingAdd);
                    // setNewAddClick(false);
                    setOrderClick(false);
                    // setPaymentClick(false);
                    // setSelectedAddToggle(!selectedAddToggle);
                  }}
                >
                  {!billingAdd ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </span>
              </div>
              {billingAdd && <AddBillingAddress />}
            </div>
            {/* delivery address */}
            <div className="address-dropdown dropdownlist">
              <div className="list">
                <p
                  className=" cursor-pointer hover:text-[#d9d9d9] text-white mb-0"
                  onClick={() => {
                    setSavedAdd(!savedAdd);
                    // setNewAddClick(false);
                    setOrderClick(false);
                    // setPaymentClick(false);
                    setSelectedAddToggle(!selectedAddToggle);
                  }}
                >
                  <span className="num">2</span> Delivery Address
                </p>
                <span
                  className=" cursor-pointer"
                  onClick={() => {
                    setSavedAdd(!savedAdd);
                    // setNewAddClick(false);
                    setOrderClick(false);
                    // setPaymentClick(false);
                    setSelectedAddToggle(!selectedAddToggle);
                  }}
                >
                  {!savedAdd ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </span>
              </div>
              {savedAdd && (
                <SavedAddress
                  setSavedAdd={setSavedAdd}
                  setDeliveryAdd={setDeliveryAdd}
                  setOrderClick={setOrderClick}
                  setSelectedAddToggle={setSelectedAddToggle}
                  addToggle={addToggle}
                  setAddToggle={setAddToggle}
                />
              )}
              {selectedAddToggle && !savedAdd && (
                <div className="address-cont">
                  {userDetail && deliveryAdd != null ? (
                    <>
                      <div className="flex flex-col gap-[6px]">
                        <div className="flex flex-row gap-[20px]">
                          <img src={checkBox} alt="Ehs Address" />
                          <div className="add-Name">
                            <p className="text-[#003459] text-base font-medium mb-0">
                              {userDetail.address[deliveryAdd] &&
                                userDetail.address[deliveryAdd].name}
                            </p>
                            <span className="ml-8">
                              <p className="text-sm font-medium mb-0">
                                {userDetail.address[deliveryAdd] &&
                                  userDetail.address[deliveryAdd].type}
                              </p>
                            </span>
                          </div>
                        </div>

                        <div className="text-black ml-[38px]">
                          <p className=" text-sm mb-0">
                            {userDetail.address[deliveryAdd] &&
                              userDetail.address[deliveryAdd].Area_Street}
                            {" , "}
                            {userDetail.address[deliveryAdd] &&
                              userDetail.address[deliveryAdd].city}
                          </p>
                          <p className=" text-sm mb-0">
                            {userDetail.address[deliveryAdd] &&
                              userDetail.address[deliveryAdd].pincode}{" "}
                            {userDetail.address[deliveryAdd] &&
                              userDetail.address[deliveryAdd].state}
                            {" , "}
                            {userDetail.address[deliveryAdd] &&
                              userDetail.address[deliveryAdd].country}
                          </p>
                          <p className=" text-sm mb-0">
                            {userDetail.address[deliveryAdd] &&
                              userDetail.address[deliveryAdd].phone}
                          </p>
                        </div>
                        <div
                          className=" flex flex-row gap-2 items-center py-1 px-3 rounded-[5px] border-[1px] border-[#003459] mt-2 w-[170px] cursor-pointer ml-9"
                          onClick={() => {
                            setAddToggle(!addToggle);
                          }}
                        >
                          <img src={plusIcon} alt="EHS add address" />
                          <p className=" text-sm text-[#003459] mb-0">
                            Add New Address
                          </p>
                        </div>
                      </div>
                      {/* Adding New Address */}
                      <AddressPopup popup={addToggle}>
                        <div className=" flex flex-col gap-2 w-full">
                          <div className="type2 mt-2 ml-10 flex flex-row justify-between items-center">
                            <div className="checkbx flex flex-row gap-3 items-center -ml-[2px]">
                              {/* <input type="checkbox"  /> */}
                              {/* {userDetail?.billing_address[0] ?} */}
                              <input
                                className="radio"
                                type="checkbox"
                                id="default"
                                name="default"
                                onClick={(e) => {
                                  setIsDeliveryAddressSame(
                                    !isDeliveryAddressSame
                                  );
                                  handleSameAddress(e);
                                }}
                              />
                              <label
                                for="default"
                                className="text-[#003459] text-sm font-medium"
                              >
                                Delivery address same as billing address
                              </label>
                            </div>
                            <img
                              src={closeIcon}
                              alt="EHS Close Icon"
                              className="w-[20px] mr-3 cursor-pointer"
                              onClick={() => {
                                setAddToggle(!addToggle);
                              }}
                            />
                          </div>
                          <div className="address-form">
                            <div className="input">
                              <input
                                type="text"
                                name="phone"
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]?.phone
                                    : userAddress?.phone
                                }
                                onChange={handleNewAddChange}
                                // placeholder="Alternate Number (Optional)"
                                placeholder="Phone No."
                              />
                              <input
                                type="text"
                                name="name"
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]?.name
                                    : userAddress?.name
                                }
                                onChange={handleNewAddChange}
                                placeholder="Name"
                                maxLength={30}
                              />
                            </div>
                            <div>
                              <textarea
                                className="textarea"
                                id=""
                                cols="30"
                                rows="3"
                                type="text"
                                name="Area_Street"
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]
                                        ?.Area_Street
                                    : userAddress?.Area_Street
                                }
                                onChange={handleNewAddChange}
                                placeholder="Address(Area and street)"
                                maxLength={70}
                              ></textarea>
                            </div>
                            <div className="input">
                              <input
                                type="text"
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]?.city
                                    : userAddress?.city
                                }
                                onChange={handleNewAddChange}
                                name="city"
                                placeholder=" city"
                              />
                              <input
                                type="number"
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]?.pincode
                                    : userAddress?.pincode
                                }
                                onChange={handleNewAddChange}
                                name="pincode"
                                placeholder=" pincode"
                              />
                            </div>
                            <div className="input">
                              {/* <input
                type="text"
                value={state}
                onChange={handleChange}
                name="state"
                placeholder="state"
              /> */}
                              <select
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]?.state
                                    : userAddress?.state
                                }
                                id="country-state"
                                name="state"
                                onChange={handleNewAddChange}
                              >
                                <option value="" disabled selected hidden>
                                  Select State...
                                </option>
                                <option value="Andaman and Nicobar Islands">
                                  Andaman and Nicobar Islands
                                </option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Dadra and Nagar Haveli">
                                  Dadra and Nagar Haveli
                                </option>
                                <option value="Daman and Diu">
                                  Daman and Diu
                                </option>
                                <option value="Delhi">Delhi</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jammu and Kashmir">
                                  Jammu and Kashmir
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Ladakh">Ladakh</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </select>
                              <input
                                type="text"
                                value={
                                  isDeliveryAddressSame
                                    ? userDetail?.billing_address[0]?.country
                                    : userAddress?.country
                                }
                                onChange={handleNewAddChange}
                                name="country"
                                placeholder="country"
                              />
                            </div>

                            <div className="type">
                              <div>
                                <div>
                                  <input
                                    className="radio"
                                    type="radio"
                                    id="work"
                                    name="type"
                                    value="work"
                                    onClick={(e) => {
                                      // settype(e.target.value);
                                      userAddress.type = e.target.value;
                                    }}
                                  />
                                  <label for="work" className="ml-2">
                                    work
                                  </label>
                                </div>
                                <div>
                                  <input
                                    className="radio"
                                    type="radio"
                                    id="home"
                                    name="type"
                                    value="home"
                                    onClick={(e) => {
                                      // settype(e.target.value);
                                      userAddress.type = e.target.value;
                                    }}
                                  />
                                  <label for="home" className="ml-2">
                                    home
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button
                              className="saved-btn1"
                              onClick={SaveAddress}
                              style={{ width: "60px", padding: "10px" }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </AddressPopup>
                    </>
                  ) : (
                    userDetail.address.length > 0 &&
                    userDetail.address[defaultIndex] && (
                      <div className="delivery-add">
                        <p className="mb-0">
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].name}
                          {" , "}
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].phone}
                          {" , "}
                        </p>
                        <p className="mb-0">
                          {/* {"Ph-"}{userDetail.phonenumber}{" , "} */}
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].Area_Street}
                        </p>
                        <p className="mb-0">
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].city}
                          {" , "}
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].pincode}{" "}
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].lat}{" "}
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].lon}
                        </p>
                        <p className="mb-0">
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].state}
                          {" , "}
                          {userDetail.address[defaultIndex] &&
                            userDetail.address[defaultIndex].country}
                        </p>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>

          {/* <div className="add-address-dropdown dropdownlist hidden">
            <div className="list">
              <p
                className=" cursor-pointer hover:text-[#d9d9d9] text-white"
                onClick={() => {
                  setNewAddClick(!newAddClick);
                  setOrderClick(false);
                  setSavedAdd(false);
                  setPaymentClick(false);
                }}
              >
                <span className="num plus-sign">+</span> Add Address
              </p>
              <span
                className=" cursor-pointer"
                onClick={() => {
                  setNewAddClick(!newAddClick);
                  setOrderClick(false);
                  setSavedAdd(false);
                  setPaymentClick(false);
                }}
              >
                {!newAddClick ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </span>
            </div>
            {newAddClick && (
              <NewAddress
                setSavedAdd={setSavedAdd}
                setDeliveryAdd={setDeliveryAdd}
                setOrderClick={setOrderClick}
                setSelectedAddToggle={setSelectedAddToggle}
                setConfirmDelivNo={setConfirmDelivNo}
              />
            )}
          </div> */}
          <div className="order-dropdown dropdownlist">
            <div className="list">
              <p
                className=" cursor-pointer hover:text-[#d9d9d9] text-white mb-0"
                onClick={() => {
                  setOrderClick(!orderClick);
                  // setPaymentClick(false);
                  // setNewAddClick(false);
                  setSavedAdd(false);
                }}
              >
                <span className="num">3</span> Order Summary
              </p>
              <span
                className=" cursor-pointer"
                onClick={() => {
                  setOrderClick(!orderClick);
                  // setPaymentClick(false);
                  // setNewAddClick(false);
                  setSavedAdd(false);
                }}
              >
                {!orderClick ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </span>
            </div>
            {orderClick && (
              <div className="order-summary">
                {state.cart[0] &&
                  state.cart.map((elem) => {
                    console.log(elem, "PAYMENT CHECK++");
                    return (
                      <OrderComp
                        key={elem._id}
                        productData={elem}
                        qty={elem.quantity}
                        name={
                          elem.poster_details
                            ? elem.poster_details.name
                            : elem.pageDetail
                            ? elem.pageDetail.name
                            : "Customized Product"
                        }
                        dimension={elem.materialDimension.dimension_title}
                        material={elem.materialDimension.material_title}
                        total={elem.total}
                        price={elem.materialDimension.price}
                        desc={
                          elem.poster_details
                            ? elem.poster_details.description
                            : elem.pageDetail.materialDimension.description
                        }
                        img={
                          elem?.poster_details
                            ? elem?.poster_details?.imgUrl[0]
                              ? elem?.poster_details?.imgUrl[0]
                              : elem?.poster_details?.imgUrl
                            : elem?.pageDetail?.imgUrl
                        }
                      />
                    );
                  })}
              </div>
            )}
          </div>

          {/* <div className="payment-dropdown dropdownlist">
            <div className="list">
              <p>
                <span className="num">3</span> Proceed to Payment
              </p>
              <span
                onClick={() => {
                  setPaymentClick(!paymentClick);
                  setOrderClick(false);
                  setNewAddClick(false);
                  setSavedAdd(false);
                }}
              >
                {!paymentClick ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </span>
            </div>
            {paymentClick && (
              <div className="payment-btn-box">
                <button className="payment-btn">Proceed to Payment</button>
              </div>
            )}
          </div> */}
          {/* Payment btn ----- */}
          {/* <div className="payment-btn-box">
            <button
              onClick={placeOrderHandler}
              // disabled={deliveryAdd === null || state.cart <= 0}
              disabled={isDisabled}
              className="payment-btn"
            >
              {isDisabled ? "Select a delivery address" : "Proceed to Payment"}
            </button>
          </div> */}
        </div>
        <div className="payment-detail payement-right">
          <div className="product">
            {/* <img src={covidpic} alt="#img" /> */}
            {/* <p>{state.cart.length} Item{state.cart.length>1?"s":""} (Estimated Delivery 6 days)</p> */}
          </div>
          <div className="price-detail">
            <h4>
              {" "}
              Price Details ({state.cart.length} Product
              {state.cart.length > 1 ? "s" : ""})
            </h4>
            <div>
              <p className=" text-sm mb-[10px]">Total MRP</p>
              <p className=" text-sm mb-[10px]"> &#8377; {cartTotal}</p>
            </div>
            <div>
              <p className=" text-sm mb-[10px]">Discount on MRP</p>
              <p id="mrp" className=" text-sm mb-[10px]">
                {" "}
                {ehstrialCoupon ? (
                  <>0</>
                ) : ehs20 ? (
                  <>0</>
                ) : (
                  <>
                    {cartTotal ? "-" : ""} ₹ {cartTotal ? discount : "0"}{" "}
                  </>
                )}
              </p>
            </div>
            {/* <div>
              <p className="mb-0">Coupon Discount</p>
              <p id="coupon" className="mb-0">
                {" "}
                Apply Coupon{" "}
              </p>
            </div> */}
            <div className="flex flex-col gap-2 bg-slate-200 w-full px-1 py-1">
              <div className="flex justify-between w-full items-center">
                <span className="text-sm 2xl:text-base whitespace-nowrap">
                  {ehstrialCoupon ? (
                    <p className=" text-[#003459] text-sm mb-0">
                      Coupon Applied
                    </p>
                  ) : ehs20 ? (
                    <p className=" text-[#003459] text-sm mb-0">
                      Coupon Applied
                    </p>
                  ) : (
                    <>Coupon Discount</>
                  )}
                  {couponError ? (
                    <>
                      <p className="mb-0 text-red-500 font-semibold text-xs italic pt-2">
                        {couponError}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* {ehstrialCoupon || ehs20 ? (
                    <>
                      <p className=" text-sm mb-0">
                        {ehstrialCoupon ? (
                          <p className="mb-0">"EHSTRIAL" coupon applied</p>
                        ) : ehs20 ? (
                          <p className="mb-0">"EHS20" coupon applied</p>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className=" text-[10px] mb-0">remove</p>
                    </>
                  ) : (
                    <></>
                  )} */}
                </span>
                <div className=" text-right text-[#003459] opacity-80 hover:opacity-100 mb-0">
                  {ehstrialCoupon ? (
                    <>
                      <span>- ₹</span>{" "}
                      <span classname=" text-sm mb-0">{cartTotal}</span>
                    </>
                  ) : ehs20 ? (
                    <>
                      <span>- ₹</span>{" "}
                      <span classname=" text-sm mb-0">{ehs20}</span>
                    </>
                  ) : (
                    <span
                      className=" cursor-pointer mb-0"
                      onClick={() => setDisplayCoupon(!displayCoupon)}
                    >
                      Apply Coupon
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-2 justify-between items-center">
                <input
                  onChange={(e) => {
                    setCoupon(e.target.value);
                  }}
                  type="text"
                  className={displayCoupon ? "applyCoupon" : "hideApplyCoupon"}
                  placeholder="Apply Coupon"
                />
                <p
                  className={`${
                    displayCoupon ? "inline-block" : "hidden"
                  } px-3 py-1 text-sm text-[#003459] border-[1px] rounded-md border-[#003459] max-w-[100px] mb-[10px] mt-[15px] hover:bg-[#003459] hover:text-white cursor-pointer transition-all`}
                  onClick={() => {
                    setDisplayCoupon(!displayCoupon);
                    handleApplyCoupon();
                  }}
                >
                  Submit
                </p>
              </div>
            </div>
            <div className=" mt-2">
              <p className=" text-sm mb-[10px]">Delivery Charge MRP</p>
              <p className=" text-sm mb-[10px]">
                {" "}
                {cartTotal
                  ? cartTotal - discount >= 2000
                    ? "FREE"
                    : "+ ₹50"
                  : "₹0"}
              </p>
            </div>
            <div className="pay-line my-2"></div>
            <div className="total-ammount  mt-2">
              <p className=" text-sm mb-[10px]">Total Amount</p>
              <p className=" text-sm mb-[10px]">
                {" "}
                {ehstrialCoupon ? (
                  <>
                    {cartTotal
                      ? cartTotal - discount >= 2000
                        ? "FREE"
                        : "+ ₹50"
                      : "₹0"}
                  </>
                ) : (
                  <>
                    ₹
                    {cartTotal && ehstrialCoupon
                      ? cartTotal + deliveryCharge - (discount + ehstrialCoupon)
                      : cartTotal && ehs20
                      ? cartTotal + deliveryCharge - ehs20
                      : cartTotal && ehstrialCoupon === null && ehs20 === null
                      ? cartTotal + deliveryCharge - discount
                      : 0}
                  </>
                )}
                {/* {cartTotal ? cartTotal + deliveryCharge - discount : 0} */}
              </p>
              
            </div>
            
            <div className="payment-btn-box">
            <button
              onClick={placeOrderHandler}
              // disabled={deliveryAdd === null || state.cart <= 0}
              disabled={isDisabled}
              className="payment-btn"
            >
              {isDisabled ? "Select a delivery address" : "Proceed to Payment"}
            </button>
          </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
