import axios from 'axios';
import { API, API1 } from '../backend';

export const get_User = async (id) => {
    return await axios.get(`${API1}auth/getsingleUser/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            //console.log(err)
            return null;
        })
}