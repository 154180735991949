import React from "react";
import "./termsAndPrivacy.css";
import { useNavigate } from "react-router-dom";
// import { privacyPolicyAPI } from "./privacyPolicyAPI";
const PrivacyPolicy = () => {
  const topView = () => {
    window.scrollTo({ top: 0 });
  };

  topView();

  const navigate = useNavigate();
  const backToPreviousPage = () => {
    navigate(-1);
  };
  return (
    <>
      <div className=" w-[80vw] mx-auto mt-[50px]">
        <h4 className="text-[#000000] text-2xl text-center">Privacy Policy</h4>
        <p className="mt-2">
          This Privacy Policy describes how www.EHSprints.com collects, uses and
          discloses your Personal Information when you visit or make a purchase
          from the Site.
        </p>
        <div className=" mt-5">
          <div>
            <p className=" font-bold text-base mb-2">
              Collecting Personal Information
            </p>
            <p className="mt-0 mb-4">
              When you visit the site, we collect certain information about your
              device, your interaction with the Site, and information necessary
              to process your purchases. We may also collect additional
              information if you contact us for customer support. In this
              Privacy Policy, we refer to any information that can uniquely
              identify an individual (including the information below) as
              “Personal Information”. See the list below for more information
              about what Personal Information we collect and why.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Device information</p>
            <p className="mt-0 mb-4">
              Examples of Personal Information collected: version of web
              browser, IP address, time zone, cookie information, what sites or
              products you view, search terms, and how you interact with the
              Site. Purpose of collection: To load the Site accurately for you,
              and to perform analytics on Site usage to optimize our Site.
              Source of collection: Collected automatically when you access our
              Site using cookies, log files, web beacons, tags, or pixels.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Order information</p>
            <p className="mt-0 mb-4">
              {" "}
              Examples of Personal Information collected: name, billing address,
              shipping address, payment information (including credit card
              numbers), email address and phone number. Purpose of collection:
              To provide products or services to you to fulfil our contract, to
              process your payment information, arrange for shipping, and
              provide you with invoices and/or order confirmations, communicate
              with you, screen our orders for potential risk or fraud, and when
              in line with the preferences you have shared with us, provide you
              with information or advertising relating to our products or
              services.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Minors</p>
            <p className="mt-0 mb-4">
              The site is not intended for individuals under the age of 16. We
              do not intentionally collect Personal information from children.
              If you are the parent or guardian and believe your child has
              provided us with Personal Information, please contact us at the
              address below to request deletion.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Behavioural Advertising</p>
            <p className="mt-0 mb-4">
              As described above, we use your Personal Information to provide
              you with targeted advertisements or marketing communications we
              believe may be of interest to you. For example: We use Google
              Analytics to help us understand how our customers use the Site.
              You can read more about how Google uses your Personal Information
              here:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                className=" hover:text-blue-700 hover:underline text-blue-500"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              . You can also opt-out of Google Analytics here:{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                className=" hover:text-blue-700 hover:underline text-blue-500"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
              . We share information about your use of the Site, your purchases,
              and your interaction with our ads on other websites with our
              advertising partners. We collect and share some of this
              information directly with our advertising partners, and in some
              cases through the use of cookies or other similar technologies
              (which you may consent to, depending on your location).For more
              information about how targeted advertising works, you can visit
              the Network Advertising Initiative’s (“NAI”) educational page at{" "}
              <a
                href="http://www.networkadvertising.org/understanding-onlineadvertising/how-does-it-work"
                className=" hover:text-blue-700 hover:underline text-blue-500"
              >
                http://www.networkadvertising.org/understanding-onlineadvertising/how-does-it-work
              </a>
              . You can opt out of targeted advertising by: FACEBOOK -{" "}
              <a
                href="https://www.facebook.com/settings/?tab=ads"
                className=" hover:text-blue-700 hover:underline text-blue-500"
              >
                https://www.facebook.com/settings/?tab=ads
              </a>{" "}
              GOOGLE -{" "}
              <a
                href="https://www.google.com/settings/ads/anonymous"
                className=" hover:text-blue-700 hover:underline text-blue-500"
              >
                https://www.google.com/settings/ads/anonymous.
              </a>{" "}
              Additionally, you can opt out of some of these services by
              visiting the Digital Advertising Alliance’s opt-out portal at:{" "}
              <a
                href="http://optout.aboutads.info/"
                className=" hover:text-blue-700 hover:underline text-blue-500"
              >
                http://optout.aboutads.info/
              </a>
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">
              Using Personal Information
            </p>
            <p className="mt-0 mb-4">
              We use your personal Information to provide our services to you,
              which includes: Offering products for sale, processing payments,
              shipping and fulfilment of your order, and keeping you up to date
              on new products, services, and offers.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Retention</p>
            <p className="mt-0 mb-4">
              When you place an order through the Site, we will retain your
              Personal Information for our records unless and until you ask us
              to erase this information.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Changes</p>
            <p className="mt-0 mb-4">
              We may update this Privacy Policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal, or regulatory reasons.
            </p>
          </div>
          <div className="mt-5">
            <p className=" font-bold text-base mb-2">Contact</p>
            <p className="mt-0 mb-4">
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please
              contact us by e-mail at hello@ehsprints.com.If you are not
              satisfied with our response to your complaint, you have the right
              to lodge your complaint with the relevant data protection
              authority. You can contact your local data protection authority,
              or our supervisory authority.
            </p>
          </div>
        </div>
        <button
          onClick={backToPreviousPage}
          className="bg-[#003459] w-[100px] py-2 text-white rounded-md cursor-pointer"
        >
          Go Back
        </button>
      </div>
    </>
  );
};

export default PrivacyPolicy;
