import { combineReducers } from "redux";
import handleCart from "./cartReducer";
import handlePoster from "./posterReducer";
import  { handleUser } from "./userReducer";
import handleProduct from "./productReducer";
import handleItem from "./categoryReducer";
import handleDIY from "./rootDIY";
import handleForm from "./formReducer";

const rootReducer = combineReducers({
    form:handleForm,
    cart: handleCart,
    poster:handlePoster,
    user: handleUser,
    product: handleProduct,
    category:handleItem,
    projects: handleDIY 
})

export default rootReducer