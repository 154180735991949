const initialState = {
  userDetail: null,
  singleUser:null,
  loginResponse: Number,
  updatedDetail: null,
  responseMessage: "",
  refresh: Math.random(),
  toEditAddress: null,
  toEditBillingAddress: null,
  toEditSameAddress: null,
  orders: [],
  orderTrack: {},
  wishlist: localStorage.getItem("wishlist")
    ? JSON.parse(localStorage.getItem("wishlist"))
    : [],
  singleOrder: [],
  storedString: "",
};

export const handleUser = (state = initialState, { type, payload }) => {
  // //console.log(payload);
  switch (type) {
    case "GET_DATA_FOR_OTP":
      return {
        ...state,
        storedSignupData: payload,
      };

      case "GET_SINGLE_USER":
        return{
          ...state,
          singleUser:payload
        };
        
    case "GET_USER_RESPONSE":
      return {
        ...state,
        userDetail: payload.data.user_details,
        responseMessage: payload.message,
        loginResponse: payload.success,
        refresh: Math.random(),
      };

    case "GET_USER_DETAIL":
        // alert("userDetail fetched")
      return { ...state, userDetail: payload };

    case "UPDATE_USER_ADDRESS_OR_WISHLIST":
      return { ...state, userDetail: payload };

    case "USER_SIGNOUT": {
      // alert("usersignout");
      localStorage.removeItem("myuser");
      localStorage.removeItem("popUp");
      localStorage.removeItem("businessId");
      localStorage.removeItem("createPopup");
      // localStorage.removeItem('cart')
      return {
        userDetail: null,
        responseMessage: "",
        loginResponse: payload,
        refresh: Math.random(),
        wishlist: JSON.parse(localStorage.getItem("wishlist")),
      };
    }
    case "EDIT_USER_ADDRESS":
      return { ...state, toEditAddress: payload };

    case "EDIT_USER_BILLING_ADDRESS":
      return { ...state, toEditBillingAddress: payload };

    case "EDIT_SAME_ADDRESS":
      return { ...state, toEditSameAddress: payload };

    case "GET_USER_ORDERS":
      return { ...state, orders: payload };

    case "REMOVE_WISHLIST":
      return { ...state, userDetail: payload };

    case "TRACK_YOUR_ORDER":
      return { ...state, orderTrack: payload };

    case "ADDWLIST": {
      localStorage.setItem("wishlist", JSON.stringify(payload));
      return { ...state, wishlist: payload };
    }
    case "GET_SINGLE_ORDER": {
      return { ...state, singleOrder: payload };
    }
    case "STORE_STRING":
      return {
        ...state,
        storedString: payload,
      };

    default:
      return state;
  }
};
