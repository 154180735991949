import React, { memo } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import EhsLogo from "../../../images/EhsLogo.svg";
import behance from "../../../images/icons/behance-fill.svg";
import facebook from "../../../images/icons/facebook.svg";
import instagram from "../../../images/icons/instagram.svg";
import twitter from "../../../images/icons/twitter.svg";
import linkedIn from "../../../images/icons/logo-linkedin.svg";

// import SearchIcon from "'@mui/icons-material/Search";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Footer = () => {
  const data = useSelector((state) => state.user.userDetail);
  // console.log(data, "data from footer =>>>>>>>>>>>>>>>>>");

  const options = { year: 'numeric' };
  const formattedDate = new Date().toLocaleDateString('en-US', options);
  return (
    <footer className="footer">
      <div className="maxwidth">
        <div className="flexible_container">
          {/* Contact us Starts from here */}
          <div className="info_card">
            <a href="/">
              {" "}
              <LazyLoadImage
                effect="opacity"
                className="footerLogo"
                src={EhsLogo}
                alt=""
              />
            </a>
            <h2>Contact Us</h2>
            <p className="details">
              Timings (Mon-Sat: 09:00 - 17:00) <br />
              45, Old Agrawal Nagar, Indore, <br />
              Madhya Pradesh, Pin: 452001 <br />
              Mobile No : +91 83100 26114 <br />
              Email ID : hello@ehsprints.com
            </p>
            <div className="icons-box">
              <a
                href="https://www.facebook.com/ehsprints/"
                target="_blank"
                rel="norefferer noreferrer"
              >
                <img src={facebook} alt="" className=" w-7 h-7 img" />
              </a>
              <a
                href="https://instagram.com/ehs.prints?igshid=ZDdkNTZiNTM="
                target="_blank"
                rel="norefferer noreferrer"
              >
                <img src={instagram} alt="" className=" w-7 h-7 img" />
              </a>
              <a
                href="https://twitter.com/ehsprints"
                target="_blank"
                rel="norefferer noreferrer"
              >
                <img src={twitter} alt="" className=" w-7 h-7 img" />
              </a>
              <a
                href="https://www.behance.net/ehsprint"
                target="_blank"
                rel="norefferer noreferrer"
              >
                <img src={behance} alt="behance" className=" w-7 h-7 img" />
              </a>

              <a
                href="https://www.linkedin.com/company/ehs-prints"
                target="_blank"
                rel="norefferer noreferrer"
              >
                <img src={linkedIn} alt="" className=" w-7 h-7 img" />
              </a>
            </div>
          </div>

          {/* Products Starts from here */}
          <div className="info_card info_card2 footer__link__container">
            <h2>Products</h2>
            <Link to="/category/posters">Posters</Link> <br />
            <Link to="/category/signages">Signages</Link> <br />
            <Link to="/category/floor-graphics">Floor Graphics</Link> <br />
            <Link to="/category/asset-markings">Asset Markings</Link> <br />
            <Link to="/category/utility-stickers">Utility Stickers</Link> <br />
            <Link to="/resources">Resources</Link> <br />
            <Link to="/comingsoon">Campaigns</Link> <br />
            {/* <Link to="/diy">Create Your Own</Link> */}
            <Link to="/comingsoon">Create Your Own</Link>
          </div>
          {/* My Account Starts from here */}
          <div className="info_card info_card2 footer__link__container">
            <h2>My Account</h2>
            {data?.emailid ? (
              <>
                <Link to="/userdashboard/4">Profile</Link> <br />
                <Link to="/userdashboard/1">Order History</Link> <br />
                <Link to="/userdashboard/8">Order Tracking</Link> <br />
                <Link to="/signup">Create an Account</Link>
              </>
            ) : (
              <>
                <Link to="/wishlistpopup">Profile</Link> <br />
                <Link to="/wishlistpopup">Order History</Link> <br />
                <Link to="/wishlistpopup">Order Tracking</Link> <br />
                <Link to="/signup">Create an Account</Link>
              </>
            )}
          </div>
          {/* About Starts from here */}
          <div className="info_card info_card2 footer__link__container">
            <h2>About Us</h2>
            <Link to="/about">About Us</Link> <br />
            <Link to="/privacypolicy">Privacy Policy</Link> <br />
            <Link to="/contact">Support</Link> <br />
            {/* <Link to="/">Join Us(Affiliate)</Link> <br /> */}
            <Link
              to="/joinus"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Join Us(Affiliate)
            </Link>
            <br />
            <Link to="/refundpolicy">   Cancellation &amp; Refund Policy </Link> <br />
            <Link to="/shippinganddeliverypolicy">   Shipping &amp; Delivery Policy</Link> <br />    
            
            <Link to="/terms">Terms &amp; Conditions</Link> <br />
            <Link to="/faqs">FAQs</Link>
          </div>
        </div>

        <p className="copy_right">
          Copyright © 2023-{formattedDate}, M/S NOCTILUCENT TECHNOLOGIES LLP. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default memo(Footer);
