import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
import CartItem from "../../cartComponents/CartItem";

// import CloseIcon from "@mui/icons-material/Close";
import PopUp, { CommonPopup } from "../../commonComponents/popUp/PopUp";
import { BiRupee } from "react-icons/bi";
// sendEmailPopup
import emailPopupImg from "../../../images/popups/sendEmailPopup.svg";
import warningPopupImg from "../../../images/popups/warning.svg";
import ehsLogo from "../../../images/icons/logoEHS.jpeg";
import CloseIcon from "@mui/icons-material/Close";

import "./pdf.css";
import { useEffect } from "react";

const PdfMaker = ({sendData, quoteNumber, setToggle }) => {
  console.log("Line check send data of created time 📝📝📝",sendData);
  let address = useSelector((state) => state.user.userDetail);
  let user = useSelector((state) => state.user);

  console.log('Check user details ', user);
  console.log(
    "🚀 ~ file: AddressItem.jsx ~ line 27 ~ AddressItem ~ data",
    address
  );
  const cartInState = useSelector((state) => state.cart?.cart);
  //console.log("data", cartInState)
  
  // const [cartData, setCartData] = useState([]);
  
  const [cartTotal, setCartTotal] = useState();
  const [cartTotalDiscount, setCartTotalDiscount] = useState();
  const [cartTotalAfterDiscount, setcartTotalAfterDiscount] = useState();
  console.log(cartInState, "state=>>>>>>");
  // const [cart, setCart] = useState(sendData.cart);
  const [editPopup, setEditPopup] = useState(false);
  const [editPopup2, setEditPopup2] = useState(false);

  const [incompletePopup, setIncompletePopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const pdfExportComponent = useRef(null);
  // const [emailSend, setEmailSend] = useState(false);
  const [editBtn, setEditBtn] = useState(true);
  const [clientDetail, setClientDetails] = useState({}); //To set the shipping address
  const [billingDetail, setBillingDetail] = useState({}); //To set the billing address

  const closePopup = () => {
    setToggle(false);
    window.reload();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const sendEmailhandler = () => {
  //   if (
  //     !clientDetail.clientName ||
  //     !clientDetail.companyName ||
  //     !clientDetail.companyAdd ||
  //     !clientDetail.companyContact
  //   ) {
  //     setIncompletePopup(true);
  //   } else {
  //     setSuccessPopup(true);
  //     setEmailSend(true);
  //   }
  // };
  
  const closeIncompletePopup = () => {
    setIncompletePopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < cartInState.length; i++) {
      sum += Number(cartInState[i].total);
      setCartTotal(sum);
      let discount = Math.floor((sum * 5) / 100);
      // let discount = Math.floor((sum * 5) / 100);
      // console.log("check discount ",discount);
      // setCartTotalDiscount(discount);
      let finalTotal = sum - discount;
      console.log('check finaltotal ',finalTotal)
      console.log('check sum is ',sum);
      setcartTotalAfterDiscount(finalTotal);
      
      // console.log('After delivery charge see the sum is ',cartTotalAfterDiscount)
    }
    

  }, [cartInState]);

  const [cnt,setCnt] = useState(1);

  var a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ];
  var b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  //Convert a number into words
  function inWords(num) {
    console.log('check the type of num ', num, typeof num);
    num = String(num);
    if (num.length > 9) return 'overflow';
    let n = ('000000000' + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = '';
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) +
        'crore '
        : '';
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
        : '';
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) +
        'thousand '
        : '';
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) +
        'hundred '
        : '';
    str +=
      n[5] != 0
        ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]])
        : '';
    return str;
  }
  


  return (
    <>
    
      <div className="pdf-popup">
        <div className="pdf-popupbox">
          <div className="pdf-div">
            <PDFExport
              paperSize="A4"
              margin="1cm"
              className="pdf-padding"
              ref={pdfExportComponent}
              fileName="Quotation"
            >
              <div className="pdf">

                {/* Here we have three different blocks  */}
                <div className="pdf-top">

                  <div className="logo-div">
                    <img
                      src={ehsLogo}
                      className="logo w-[8rem] h-[3rem]"
                      alt="Logo"
                    />
                    <div>
                      <p style={{ fontSize: '12px' }} >Tax Quotation/Bill of Supply/Cash Demo</p>

                      <p className="mt-0" style={{ fontSize: '12px' }}>
                        {sendData.created_at?.slice(8, 10)}
                        {sendData.created_at?.slice(4, 8)}
                        {sendData.created_at?.slice(0, 4)}
                      </p>

                    </div>
                  </div>

                  <div className="flex " style={{ width: "100%" }} >
                    <div className="detail-div" style={{ width: "30%" }}>
                    
                      <h5 className="comp-name">EHS Prints</h5>
                      <p>+91 6265246491 </p>
                      <p>hello@ehsprints.com</p>
                      <p>
                        45, Old Agarwal Nagar, Indore,
                        <br />
                        Madhya Pradesh-452001
                      </p>
                    </div>
                    <div className="pdf-mid" style={{ width: "70%" }}>
                      <div className="mid-left" style={{ width: "40%" }}>
                        <div className="user-detail">
                          <h5 style={{fontWeight:"bold"}}> Shipping Address</h5>
                          <p>
                            {clientDetail.clientName
                              ? clientDetail.clientName
                              : address.address
                                ? address.name
                                : "Client Name"}
                          </p>
                          <p>
                            {clientDetail.companyAdd
                              ? clientDetail.companyAdd
                              : "Add Shipping Address"}
                          </p>
                          <p>
                            {clientDetail.companyContact
                              ? clientDetail.companyContact
                              : address.address[0]?.phone
                                ? address.address[0]?.phone
                                : address.phonenumber
                                  ? address.phonenumber
                                  : "Contact Details"}
                          </p>
                          <p>Place of supply: {"  "}
                            {clientDetail.companyState
                              ? clientDetail.companyState
                                  : "Add Your State"}
                          </p>
                          {/* <p className="my-2">Place Of Supply:{"  "} 
                            {clientDetail.companyState
                              ? clientDetail.companyState
                              : "Add Company Address"}
                          </p> */}
                        </div>
                        {editBtn && (
                          <button
                            className="edit-btn"
                            onClick={() => setEditPopup(true)}
                          >
                            {" "}
                            Edit
                          </button>
                        )}
                        
                      </div>

                      <div className="mid-left" style={{ width: "40%" }}>
                        <div className="user-detail">
                          <h5 style={{fontWeight:"bold"}}> Billing Address</h5>
                          <p>
                            {billingDetail.clientName
                              ? billingDetail.clientName
                              : address.billing_address
                                ? address.name
                                : "Client Name"}
                          </p>
                          {/* <p>
                            {clientDetail.clientName
                              ? clientDetail.clientName
                              : address.billing_address
                                ? address.name
                                : "Client Name"}
                          </p> */}
                          {/* <p>
                            {billingDetail.companyName
                              ? billingDetail.companyName
                              : "Add Company Name"}
                          </p> */}
                          <p>
                            {billingDetail.companyAdd
                              ? billingDetail.companyAdd
                              : "Add billing Address"}
                          </p>
                          <p>
                            {billingDetail.companyContact
                              ? billingDetail.companyContact
                              : address.billing_address[0]?.phone
                                ? address.billing_address[0]?.phone
                                : address.phonenumber
                                  ? address.phonenumber
                                  : "Contact Details"}
                          </p>
                          <p>Place of supply: {"  "}
                            {clientDetail.companyState
                              ? clientDetail.companyState
                                  : "Add Your State"}
                          </p>
                          {/* <p  className="my-2">Place Of Delivery:{"  "}
                            {billingDetail.billingState
                              ? billingDetail.billingState
                              : "Add billing Address"}
                          </p> */}
                        </div>
                        {editBtn && (
                          <button
                            className="edit-btn"
                            onClick={() => setEditPopup2(true)}
                          >
                            {" "}
                            Edit
                          </button>
                        )}
                       
                      </div>
                    </div>
                    {/* <div className="mid-right">
                        <div className="flex items-center mb-[10px]">
                          <p className="name ">Quotation number:</p>
                          <p className="mt-0">0{quoteNumber + 1}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="name ">Date:</p>

                        {/* </div> */}
                    {/* </div>
                    </div>  */}
                  </div>

                  {/* <div className="pdf-line"></div> */}
                </div>

                {/* <div className="pdf-line"></div> */}
                <div className="pdf-item" >
                  <table>
                    <thead style={{ backgroundColor: "#FC427D" }}>
                      {/* <thead style={{backgroundColor : "#FC427B"}}> */}
                      <tr className="item-head">
                        <th className="item2">
                          <p className="text-white" style={{display:"flex", justifyContent:"left"}} >Sr.No.</p>
                        </th>
                        <th className="item1">
                          <p className="text-white" style={{display:"flex", justifyContent:"center"}}>Product Description</p>
                        </th>
                        <th className="item2">
                        <p className="text-white">Image</p>
                        </th>
                        <th className="item2">
                          <p className="text-white">Unit Price</p>
                        </th>
                        <th className="item2">
                          <p className="text-white">Discount</p>
                        </th>
                        <th className="item2">
                          <p className="text-white">Qty</p>
                        </th>                       
                        <th className="item2">
                          <p className="text-white">Net Amt</p>
                        </th>
                        <th className="item2">
                          <p className="text-white">Total Amt</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*  dynamic data  */}
                      
                      {cartInState.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="item-2">
                             {cnt+i}
                            </td>
                            <td className="item1">
                              <div >
                                <p className="mb-0" style={{fontSize:"11px"}}>
                                  Name:{" "}
                                  {item.poster_details?.name?.split("|")[2]}
                                </p>
                                <p className="mb-0" style={{fontSize:"11px"}}>
                                  Material:{" "}
                                  {item.materialDimension?.material_title}
                                </p>
                                <p className="mt-0" style={{fontSize:"11px"}}>
                                  Dimension:{" "}
                                  {item.materialDimension?.dimension_title}
                                </p>
                              </div>
                            </td>
                            <td className="items2">
                            <p>{item.imgUrl}</p>
                            <div>
                               </div>
                            </td>
                          
                            <td className="item2">
                              <p>{item.total}</p>
                            </td>
                            <td className="item2">
                              <p>{Math.floor((item.total * 5) / 100)}</p>
                            </td>
                            <td className="item2">
                              <p>{item.quantity}</p>
                            </td>
                            <td className="item2" >
                              <p>{item.total}</p>
                            </td>
                            <td className="item2">
                              <p>
                                <BiRupee />
                                {item.total -
                                  Math.floor((item.total * 5) / 100)}
                              </p>
                            </td>

                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Show discount payment procedure  */}
                {cartTotalAfterDiscount < 2000 ? <div className="mx-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <p>Delivery Charge</p>
                  <td className="item2">
                              <p>
                                <BiRupee />
                                {50}
                              </p>
                            </td>
                  {/* <p style={{ paddingRight: "37px" }} >- <span><BiRupee /> 50</span></p> */}
                </div> : ''}

                <div className="pdf-line"></div>

                <div className="pdf-mid">
                  <div className="pdf-left"></div>
                  <div className="pdf-right">
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <p>Total Due </p>
                  <td className="item2">
                              <p>
                                <BiRupee />
                                {cartTotalAfterDiscount<2000 ? cartTotalAfterDiscount+50 : cartTotalAfterDiscount}
                              </p>
                            </td>
                  {/* <p> <i><span style={{display:"inline"}} ><BiRupee /></span> {cartTotalAfterDiscount}</i> </p> */}
                </div>
                  </div>
                </div>

               

                {/* Write the total in words  */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>Total In Words </p>
                  <p> <i> &#x20B9; {inWords(cartTotalAfterDiscount<2000 ? cartTotalAfterDiscount+50 : cartTotalAfterDiscount)}</i></p>
                  {/* <p>&#x20B9; {typeof cartTotalAfterDiscount}</p> */}
                </div>

                <div className="pdf-line"></div>
                {/* Here we show the signature and lab sign  */}
                <div className="pdf-mid">
                  <div className="mid-left"></div>
                  <div className="mid-right">
                    <p>For Dichroic Labs LLP</p>
                    <img src="/ehs-signature.jpeg" alt="signature" width={"135px"} />
                    <p>Authorized Signatory</p>
                  </div>
                </div>

                {/* Terms and Condition here  */}
                <div className="terms">
                  <h6 className=" font-['Roboto']">
                    Terms & Conditions / Notes
                  </h6>
                  <div className=" text-xs font-normal text-[#000000]">
                    <p  className=" font-['Roboto']">
                      All the prices are inclusive of GST. This quotation is
                      valid upto 30 days. Prices for our products are subject to
                      change without notice. We reserve the right at any time to
                      modify or discontinue the Service (or any part or content
                      thereof) without notice at any time. We shall not be
                      liable to you or to any third-party for any modification,
                      price change, suspension or discontinuance of the Service
                    </p>
                  </div>
                </div>
              </div>
            </PDFExport>
            <div className="example-config">
              <button className="pdf-btn" onClick={closePopup}>
                Back
              </button>

              <button
                className="pdf-btn"
                onClick={() => {
                  // if (
                  //   !clientDetail.clientName ||
                  //   !clientDetail.companyAdd ||
                  //   !clientDetail.companyState ||
                  //   !clientDetail.companyContact
                  // ) {
                  //   setIncompletePopup(true);
                  // } else {
                    setSuccessPopup(true);
                    setEditBtn(false);
                    if (pdfExportComponent.current) {
                      pdfExportComponent.current.save();
                    }
                    // setEmailSend(true);
                  // }
                }}
              >
                Download
              </button>
              {/* <button className="pdf-btn" onClick={sendEmailhandler}>
                Send to Email
              </button> */}
            </div>
          </div>
        </div>
        <EditPopup
          setClientDetails={setClientDetails}
          setEditPopup={setEditPopup}
          editPopup={editPopup}
        />
        <EditPopup2
          setBillingDetail={setBillingDetail}
          setEditPopup2={setEditPopup2}
          editPopup2={editPopup2}
        />
      </div>
      <CommonPopup
        heading="Congrats!"
        desc="Your quote has been successfully downloaded."
        image={emailPopupImg}
        setMyPopup={setSuccessPopup}
        popup={successPopup}
        secondBtn={true}
      />
      <PopUp popup={incompletePopup}>
        <div className="materialPopup">
          <img
            src={warningPopupImg}
            alt="select configuration"
            style={{
              width: "75px",
              height: "75px",
            }}
          />
          <div
            className=""
            style={{
              color: "#898989",
              fontSize: "19px",
              textAlign: "center",
              margin: "15px",
            }}
          >
            Please enter company’s detail before proceeding further.
          </div>
          <CloseIcon className="closePopup" onClick={closeIncompletePopup} />
        </div>
      </PopUp>
    </>
  );
};
export default PdfMaker;

const EditPopup = ({ editPopup, setEditPopup, setClientDetails }) => {
  const [userData, setUserData] = useState({
    clientName: "",
    companyAdd: "",
    companyState : "",
    companyContact: ""
  });
  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserData((pVal) => {
      return { ...pVal, [name]: value };
    });
  };
  const formSubmit = () => {
    setEditPopup(false);
    setClientDetails(userData);
    console.log(userData);
  };
  return (
    <>
      {editPopup !== true ? null : (
        <div className="edit-popup">
          <div className="edit-popupBox">
            <div className="edit-box">
              <p className="heading text-center mb-2">Edit Details</p>
              <div className="form items-center">
                <div>
                  <p>Client Name</p>
                  <input
                    autoComplete="off"
                    type="text"
                    name="clientName"
                    onChange={handleChnage}
                    value={userData.clientName}
                  />
                </div>
                <div>
                <div>
                  <p>Shipping Post Address</p>
                  {/* <input type="text" name="companyName" autoComplete="off"  id="address"/> */}
                  <textarea
                    name="companyAdd"
                    autoComplete="off"
                    cols="30"
                    rows="4"
                    onChange={handleChnage}
                    value={userData.companyAdd}
                  ></textarea>
                </div>
                  <p>Shipping State</p>
                  <input
                    type="text"
                    autoComplete="off"
                    name="companyState"
                    onChange={handleChnage}
                    value={userData.companyState}
                  />
                </div>
               
                <div>
                  <p>Contact Number</p>
                  <input
                    type="number"
                    name="companyContact"
                    autoComplete="off"
                    onChange={handleChnage}
                    value={userData.companyContact}
                  />
                </div>
              
                <button
                  className="pdf-btn flex justify-center"
                  onClick={formSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const EditPopup2 = ({ editPopup2, setEditPopup2, setBillingDetail }) => {
  const [userData, setUserData] = useState({
    clientName: "",
    billingAdd: "",
    billingState:"",
    billingContact: ""
  });

  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserData((pVal) => {
      return { ...pVal, [name]: value };
    });
  };
  const formSubmit = () => {
    setEditPopup2(false);
    setBillingDetail(userData);
    console.log(userData);
  };
  return (
    <>
      {editPopup2 !== true ? null : (
        <div className="edit-popup">
          <div className="edit-popupBox">
            <div className="edit-box">
              <p className="heading text-center mb-2">Edit Details</p>
              <div className="form items-center">
                <div>
                  <p>Client Name</p>
                  <input
                    autoComplete="off"
                    type="text"
                    name="clientName"
                    onChange={handleChnage}
                    value={userData.clientName}
                  />
                </div>
                <div>
                  <p>Billing Address</p>
                  {/* <input type="text" name="companyName" autoComplete="off"  id="address"/> */}
                  <textarea
                    name="billingAdd"
                    autoComplete="off"
                    cols="30"
                    rows="4"
                    onChange={handleChnage}
                    value={userData.billingAdd}
                  ></textarea>
                </div>
                <div>
                  <p>Billing State</p>
                  {/* <input type="text" name="companyName" autoComplete="off"  id="address"/> */}
                  <input
                    name="billingState"
                    autoComplete="off"
                    cols="30"
                    rows="4"
                    onChange={handleChnage}
                    value={userData.billingState}
                  />
                </div>
                <div>
                  <p>Contact Number</p>
                  <input
                    type="number"
                    name="billingContact"
                    autoComplete="off"
                    onChange={handleChnage}
                    value={userData.billingContact}
                  />
                </div>
              
                <button
                  className="pdf-btn flex justify-center"
                  onClick={formSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
