// import {API} from "../../backend";
const initialState = {
    cart: [],
    diyCart: [],
    quotation: []
};
if (localStorage.getItem('cart')) {
    initialState.cart = JSON.parse(localStorage.getItem('cart'));
}
else {
    initialState.cart = [];
}
if (localStorage.getItem('diyCart')) {
    initialState.diyCart = JSON.parse(localStorage.getItem('diyCart'));
} else {
    initialState.diyCart = []
}


const handleCart = (state = initialState, action) => {

    switch (action.type) {
        case 'ADD_PRODUCT': {
            if (action.payload.length !== 0)
                localStorage.setItem('cart', JSON.stringify([...action.payload]));

            return {
                cart: [...action.payload]
            }
        }
        case 'ADD_DIY_PRODUCT': {
            const updatedCart = [...state.diyCart, ...action.payload];

            if (updatedCart.length !== 0) {
                localStorage.setItem('diyCart', JSON.stringify(updatedCart));
            } else {
                localStorage.removeItem('diyCart');
            }

            return {
                diyCart: updatedCart
            };
        }
        //fetch the diy_cart data from database
        case 'FETCH_DIY_PRODUCT' :
            // // const updatedCart = [...state.diyCart, ...action.payload];
            // const updatedCart = [ ...action.payload];

            // if (updatedCart.length !== 0) {
            //     localStorage.setItem('diyCart', JSON.stringify(updatedCart));
            // } else {
            //     localStorage.removeItem('diyCart');
            // }

            // return {
            //     diyCart: updatedCart
            // };

            localStorage.setItem('diyCart', JSON.stringify([...action.payload]));

            return {
                ...state,

                diyCart: action.payload
            }
        
        case 'DELETE_DIY_PRODUCT':

            localStorage.setItem('diyCart', JSON.stringify([...action.payload]));

            return {
                ...state,

                diyCart: action.payload
            }
        case 'DIY_CART_EMPTY' : 
            localStorage.setItem('diyCart', JSON.stringify([]));
            return {
                ...state,
                diyCart: [] 
            }
        case 'DELETE_PRODUCT':

            localStorage.setItem('cart', JSON.stringify([...action.payload]));

            return {
                ...state,

                cart: [...action.payload]
            }
        case 'INCREASE_QUANTITY':
            return {
                ...state,
                cart: [...action.payload]
            }
        case 'DECREASE_QUANTITY':
            return {
                ...state,
                cart: [...action.payload]
            }
        case 'EMPTY_CART':
            localStorage.setItem('cart', JSON.stringify([]));
            return {
                ...state,
                cart: []
            }
        case "UPDATE_CART_BACKEND":
            return {
                ...state,
                cart: [...action.payload]
            }
        case "QUOTATION_HISTORY": 
            return {
                ...state,
                quotation: [...action.payload]
            }
        
        //STart to implement the quotation specific stuff
        case "USER_DIY_FETCH_QUOTATION":
            return {
                ...state,
                quotation_diy:[...action.payload]
            }
        case "USER_DIY_DELETE_QUOTATION":
            return{
                ...state,
                // quotation_diy : 
            }
        case "USER_DIY_DELETE_ALL_QUOTATION":
            return{
                ...state,
                quotation_diy : [] 
            }

        default: return state
    }
}



export default handleCart;