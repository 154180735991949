import React from "react";
import "./termsAndPrivacy.css";
import { useNavigate } from "react-router-dom";
import { termsOfServices } from "./TersmOfServices";
const TermsCondition = () => {
  const topView = () => {
    window.scrollTo({ top: 0 });
  };

  topView();

  const navigate = useNavigate();
  const backToPreviousPage = () => {
    navigate(-1);
  };
  return (
    <>
      <div className=" w-[80vw] mx-auto mt-[50px]">
        <h4 className="text-[#000000] text-2xl text-center">
          Terms Of Service
        </h4>

        <div className="mt-5">
          {termsOfServices.map((item, index) => {
            return (
              <>
                <p className=" font-bold text-base mb-2">{item.heading}</p>
                <p className="mt-0 mb-4">{item.description}</p>
              </>
            );
          })}
        </div>
        <button
          onClick={backToPreviousPage}
          className="bg-[#003459] w-[100px] py-2 text-white rounded-md cursor-pointer"
        >
          Go Back
        </button>
      </div>
    </>
  );
};

export default TermsCondition;
