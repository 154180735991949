import React, { useState, useEffect } from "react";
import "./signin.css";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../backend";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch, useSelector } from "react-redux";
// import { addProduct } from "../../redux/actions/cartAction";
import { CommonPopup } from "../commonComponents/popUp/PopUp";
// import jwt_decode from "jwt-decode";
import loginPopupImg from "../../images/popups/loginPopup.svg";
// components
// import { userInfo, getUser } from "../../redux/actions/userAction";
// images
// import { Link } from "react-router-dom";
import logo from "./../../images/EhsLogo2.png";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";

const ForgetPassword = () => {
  const topView = () => {
    window.scrollTo({ top: 0 });
  };
  topView();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // let newUser;

  const [popup, setPopup] = useState(false);
  // let data = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  // useEffect(()=>{
  //   dispatch(getUser());
  // })
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("myuser")) {
      navigate("/");
    }
  }, []);
  //   let tokenn = JSON.parse(localStorage.getItem("myuser")).token;
  const handleReset = async (data) => {
    let emailid = data.email;
    let password = data.password;
    setLoading(true);
    const res = await fetch(`${API}auth/resetPassword`, {
      method: "POST",
      body: JSON.stringify({ emailid, password }),
      headers: {
        "Content-Type": "application/json",
        // "x-access-token": `${tokenn}`,
      },
    });
    const dataa = await res.json();
    setLoading(false);
    toast.success(dataa.message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
    function redirect() {
      navigate("/signin");
    }
    if (dataa.message) {
      setTimeout(redirect, 2000);
    }
  };

  return (
    <>
      <div className="signinContainer">
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="formContainer2 signInform-box">
          <div className="logoContainer mb-5">
            <div className="logo">
              <img src={logo} alt="Ehs-logo" className="w-[125px]" />
            </div>
            <p className="mb-1 text-2xl text-[#003459] font-['Roboto] mt-3">
              Change your password
            </p>
          </div>
          <form className="form mb-4" onSubmit={handleSubmit(handleReset)}>
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              {...register("email", { required: "Email is required" })}
              className="border rounded-[4px] mb-1"
            />
            {/* {errors.email && (
              <p className="text-red-400 text-xs">{errors.email?.message}</p>
            )} */}
            {/* <input
              type="password"
              placeholder="Password"
              value={password}
              className="border rounded-[4px]"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            /> */}
            <input
              type="password"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be 6 characters or longer",
                },
              })}
              className="border rounded-[4px] mb-1"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {errors.password && (
              <p className="text-red-400 text-xs mb-1">
                {errors.password?.message}
              </p>
            )}
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              className="border rounded-[4px] mb-1"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              required
            />
            {password !== confirmPassword && (
              <p className="text-sm text-red-400 mb-1">
                Password did not match!
              </p>
            )}
            <div className=" flex justify-center">
              <button
                type="submit"
                value="Sign In"
                disabled={loading}
                className="mx-auto mt-1 w-[350px] bg-[#1F485B] text-white font-semibold py-2 rounded-md disabled:cursor-not-allowed disabled:opacity-70"
              >
                {loading ? (
                  <div className=" flex flex-row gap-3 items-center justify-center">
                    <ClipLoader color="#dddddd" size={16} />
                    <p className="mb-0">Loading</p>
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
            <div id="errorBox"></div>
          </form>
        </div>
      </div>
      <CommonPopup
        heading="Great!"
        image={loginPopupImg}
        desc="You have successfully changed your password."
        secondBtn={true}
        // setMyPopup={setPopup}
        popup={popup}
      />
    </>
  );
};

export default ForgetPassword;
