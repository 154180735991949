import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const myuser = JSON.parse(localStorage.getItem("myuser"));
  let token = myuser?.token;
  const location = useLocation();

  if (token) {
    return children;
  } else if (token === null || token === undefined) {
    return (
      <Navigate to="/signin" state={{ from: location }} replace></Navigate>
    );
  }
};

export default PrivateRoute;
