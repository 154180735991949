import React from "react";
import "./popup.css";
import CloseIcon from "@mui/icons-material/Close";
import cartPopupImg from "../../../images/popups/addtocartPic.svg";
import wishListPopup from "../../../images/popups/wishlistPopup.svg";
import placeorderPic from "../../../images/popups/placeoreder.svg";
import logoutPic from "../../../images/popups/logoutPopup.svg";
import diyPopup from "../../../images/popups/diyPopup.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
const PopUp = ({ popup, children }) => {
  // //console.log(children);
  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="popupBox">{children}</div>
        </div>
      )}
    </>
  );
};

const SigninPopup = ({ popup, setMyPopup, heading, img }) => {
  const navigate = useNavigate();
  const continueShop = () => {
    navigate("/signin");
  };
  const closePopup = () => {
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={img} alt="select configuration" />
              <span className="popup-Head">User not found!!</span>
              <span className="popup-desc">{heading}</span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  Sign in
                </button>
                <Link to="/signup">
                  <button>Sign up</button>
                </Link>
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const WishlistPopup = ({ popup, setMyPopup }) => {
  const navigate = useNavigate();
  const continueShop = () => {
    navigate(-1);
  };
  const closePopup = () => {
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={wishListPopup} alt="select configuration" />
              <span className="popup-Head">Yay!</span>
              <span className="popup-desc">
                Your product has been successfully added to wishlist.
              </span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>
                <Link to="/cartpage">
                  <button>GO TO CART</button>
                </Link>
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const AddToCartPopUp = ({ popup, setMyPopup }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";
  const continueShop = () => {
    // navigate(-1);
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const closePopup = () => {
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={cartPopupImg} alt="select configuration" />
              <span className="popup-Head">Congrats</span>
              <span className="popup-desc">
                Product added to cart successfully. Would you like to proceed to
                checkout ?
              </span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>

                <Link to="/cartpage">
                  <button>GO TO CART</button>
                </Link>
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup cursor-pointer"  onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const BulkOrderPopUp = ({ popup, setMyPopup }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";
  const continueShop = () => {
    // navigate(-1);
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const closePopup = () => {
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={cartPopupImg} alt="select configuration" />
              <span className="popup-Head">Congrats</span>
              <span className="popup-desc">
                Your orders is successfully placed.
              </span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>

                <Link to="/cartpage">
                  <button>GO TO CART</button>
                </Link>
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const CheckAddressPopup = ({ popup, setMyPopup }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";
  const continueShop = () => {
    // navigate(-1);
    // navigate(from, { replace: true });
    setMyPopup(false);
  };
  const closePopup = () => {
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={cartPopupImg} alt="select configuration" />
              <span className="popup-Head">Warning!</span>
              <span className="popup-desc">
                Address not found. Would you like to add address or continue?
              </span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>

                <Link to="/userdashboard/5">
                  <button>ADD ADDRESS</button>
                </Link>
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const QuoteSuccessPopup = ({ popup, setMyPopup }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";
  const continueShop = () => {
    // navigate(-1);
    // navigate(from, { replace: true });
    setMyPopup(false);
  };
  const closePopup = () => {
    setMyPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={cartPopupImg} alt="select configuration" />
              <span className="popup-Head">Success!</span>
              <span className="popup-desc">
                Quote added to user dashboard successfully. Would you like to
                proceed to dashboard or continue ?
              </span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>

                <Link
                  to="/userdashboard/8"
                  onClick={() => {
                    window.reload();
                  }}
                >
                  <button>GO TO QUOTATIONS</button>
                </Link>
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const WishLsitPopUp = ({
  popup,
  setMyPopup,
  image,
  desc,
  heading,
  subHead,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const login = () => {
    navigate("/signin");
  };
  const closePopup = () => {
    setMyPopup(false);
    navigate(from, { replace: true });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={image} alt="select configuration" />
              <span className=" text-lg text-[#003459] text-center font-semibold">
                {heading}
              </span>
              <div className="btn-cart flex flex-col">
                <button id="cart-btn" onClick={login}>
                  Login
                </button>
                <p className="font-medium text-[#6B6B6B]">OR</p>
                <p className="font-medium text-[#6B6B6B]">
                  Didn't have account?
                </p>
                <Link to="/signup">
                  <button>Sign up</button>
                </Link>
              </div>
              <span className="popup-desc">{desc}</span>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const CommonPopup = ({
  popup,
  image,
  setMyPopup,
  desc,
  heading,
  secondBtn,
}) => {
  const navigate = useNavigate();
  const continueShop = () => {
    navigate("/");
    window.location.reload();
  };
  const closePopup = () => {
    setMyPopup(false);
    // navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={image} alt="select configuration" />
              <span className="popup-Head">{heading}</span>
              <span className="popup-desc">{desc}</span>
              <div className="btn-cart">
                <button
                  id="cart-btn"
                  onClick={() => {
                    // window.location.reload();
                    // navigate("/");
                    continueShop();
                  }}
                >
                  CONTINUE SHOPPING
                </button>
                {secondBtn && (
                  <Link to="/cartpage">
                    <button>GO TO CART</button>
                  </Link>
                )}
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const CommonPopupForSignup = ({
  popup,
  image,
  setMyPopup,
  desc,
  heading,
  secondBtn,
}) => {
  const navigate = useNavigate();
  // const continueShop = () => {
  //   window.location.reload();
  //   navigate("/");
  // };
  const closePopup = () => {
    setMyPopup(false);
    // navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={image} alt="select configuration" />
              <span className="popup-Head">{heading}</span>
              <span className="popup-desc">{desc}</span>
              <div className="btn-cart">
                <button
                  id="cart-btn"
                  onClick={() => {
                    // window.location.reload();
                    navigate("/");
                  }}
                >
                  CONTINUE SHOPPING
                </button>
                {secondBtn && (
                  <Link to="/cartpage">
                    <button>GO TO CART</button>
                  </Link>
                )}
              </div>
            </div>
            <span
              className="cross cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const VerifyOtpPopup = ({
  popup,
  image,
  setMyPopup,
  desc,
  heading,
  secondBtn,
}) => {
  const navigate = useNavigate();
  // const continueShop = () => {
  //   window.location.reload();
  //   navigate("/");
  // };
  const closePopup = () => {
    navigate("/signin");
    // setMyPopup(false);
    // navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={image} alt="select configuration" />
              <span className="popup-Head">{heading}</span>
              <span className="popup-desc">{desc}</span>
              <div className="btn-cart">
                <button
                  id="cart-btn"
                  onClick={() => {
                    // window.location.reload();
                    navigate("/signin");
                  }}
                >
                  Login
                </button>
                {secondBtn && (
                  <Link to="/cartpage">
                    <button>GO TO CART</button>
                  </Link>
                )}
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
const VerifyCorporateOtpPopup = ({
  popup,
  image,
  setMyPopup,
  desc,
  heading,
  secondBtn,
}) => {
  const navigate = useNavigate();
  // const continueShop = () => {
  //   window.location.reload();
  //   navigate("/");
  // };
  const closePopup = () => {
    navigate("/corporatelogin");
    // setMyPopup(false);
    // navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={image} alt="select configuration" />
              <span className="popup-Head">{heading}</span>
              <span className="popup-desc">{desc}</span>
              <div className="btn-cart">
                <button
                  id="cart-btn"
                  onClick={() => {
                    // window.location.reload();
                    navigate("/corporatelogin");
                  }}
                >
                  Login
                </button>
                {secondBtn && (
                  <Link to="/cartpage">
                    <button>GO TO CART</button>
                  </Link>
                )}
              </div>
            </div>
            <span className="cross">
              <CloseIcon className="closePopup" onClick={closePopup} />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const OrderPlacedPopUp = ({ popup }) => {
  const navigate = useNavigate();
  const continueShop = () => {
    navigate("/");
  };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={placeorderPic} alt="select configuration" />
              <span className="popup-Head">Congratulations</span>
              <span className="popup-desc">
                Your order has been placed and will be delivered to you soon.
                You will receive an order confirmation email with details of
                your order.
              </span>
              <div className="btn-cart order-btn">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DiyPopUp = ({ title, msg, setMyPopup }) => {
  const navigate = useNavigate();
  const continueShop = () => {
    navigate("/");
  };
  // const closePopup = () => {
  //   setMyPopup(false);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  return (
    <>
      <div className="popup">
        <div className="cart-popupbox">
          <div className="add-to-cart-popup">
            <img src={diyPopup} alt="select configuration" />
            <span className="popup-Head">{title}!</span>
            <span className="popup-desc">‘{msg}’</span>
            <div className="btn-cart">
              <button id="cart-btn" onClick={continueShop}>
                CONTINUE SHOPPING
              </button>
              <Link to="/cartpage">
                <button>GO TO CART</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const LogOutPopup = ({ popup, setMyPopup }) => {
  const navigate = useNavigate();
  // navigate("/");
  const continueShop = () => {
    setMyPopup(false);
    navigate("/");
  };
  // const closePopup = () => {
  //   setMyPopup(false);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="cart-popupbox">
            <div className="add-to-cart-popup">
              <img src={logoutPic} alt="select configuration" />
              <span className="popup-Head">Ooops!</span>
              <span className="popup-desc">
                It seems you have signout. Please Login to have a better
                experience.
              </span>
              <div className="btn-cart">
                <button id="cart-btn" onClick={continueShop}>
                  CONTINUE SHOPPING
                </button>
                <Link to="/signin" onClick={() => setMyPopup(false)}>
                  <button>SIGN IN</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const AddressPopup = ({ popup, children }) => {
  return (
    <>
      {popup !== true ? null : (
        <div className="popup">
          <div className="popupBox">{children}</div>
        </div>
      )}
    </>
  );
};
export {
  LogOutPopup,
  OrderPlacedPopUp,
  WishlistPopup,
  AddToCartPopUp,
  DiyPopUp,
  AddressPopup,
  CommonPopup,
  WishLsitPopUp,
  CheckAddressPopup,
  QuoteSuccessPopup,
  SigninPopup,
  VerifyOtpPopup,
  CommonPopupForSignup,
  BulkOrderPopUp,
  VerifyCorporateOtpPopup,
};
export default PopUp;
