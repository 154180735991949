import React from "react";
import instruction from "../images/ResourcesSectionImage/Instruction.png";

const ResourcesAndCampaigns = () => {
  return (
    <div>
      <img
        src={instruction}
        alt="instruction of resources"
        className="w-[80vw] lg:w-[60vw] mx-auto mt-2"
      />
    </div>
  );
};

export default ResourcesAndCampaigns;
