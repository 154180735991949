
// Set a value in local storage
export const setLocalStorageItem = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        // Handle errors (e.g., if local storage is disabled or full)
        console.error("Error while setting local storage:", error);
    }
};

// Get a value from local storage
export const getLocalStorageItem = (key) => {
    try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    } catch (error) {
        // Handle errors (e.g., if local storage is disabled)
        console.error("Error while getting local storage:", error);
        return null;
    }
};

// Remove a value from local storage
export const removeLocalStorageItem = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        // Handle errors (e.g., if local storage is disabled)
        console.error("Error while removing local storage:", error);
    }
};
