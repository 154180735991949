import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { API } from "../../../backend";
import Loading from "../../commonComponents/LoadingScreen/Loading";
import comingSoon from "../../../images/popups/diyPopup.svg";
import "./SearchedProducts.css";
// import { searchResult } from "../../../redux/actions/categoryAction";

const SearchedProducts = () => {
  const storedString = useSelector((state) => state.user.storedString);
  const [categoryName, setCategoryName] = useState(null);
  const [storedSearchResult, setStoredSearchResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [fullData, setFullData] = useState(false);
  // const []
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${API}posters/getitemsByKey`, {
          params: {
            key: storedString,
            search: true,
            limit: fullData ? 45 : 15,
          },
        });

        const response = data.data.postersExists;
        console.log("response", response);

        const searchResult = response.filter((elem) =>
          elem.tags.some((item) =>
            item.toLowerCase().includes(storedString.toLowerCase())
          )
        );
        setStoredSearchResult(searchResult);
        // Loading state
        if (response.length > 0) {
          setLoading(false);
        }
        // No data found state
        if (response.length === 0) {
          setNoDataFound(true);
          setLoading(false);
        } else {
          setNoDataFound(false);
        }
        console.log("searchResult", searchResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [storedString, fullData]);

  useEffect(() => {
    storedSearchResult?.map((poster) => {
      setCategoryName(poster?.name?.split("|")[0]);
    });
  }, [storedSearchResult]);
  // very important do not remove
  const posters = categoryName === "Posters ";
  const signages = categoryName === "Signages ";
  const floorGraphics = categoryName === "Floor Graphics ";
  const assetMarkings = categoryName === "Asset Markings ";

  let linkname = "posterspecification";
  if (categoryName === "Signages " || categoryName === "Asset Markings ") {
    linkname = "specification";
  } else if (categoryName === "Floor Graphics ") {
    linkname = "assetspecification";
  }

  // To show full 75 data
  useEffect(() => {
    setFullData(false);
  }, [storedString]);

  return loading ? (
    <Loading />
  ) : noDataFound ? (
    <>
      <div className=" flex flex-col justify-center items-center">
        <div className=" mt-5">
          <img src={comingSoon} alt="coming soon pic" />
          <h5 className="mt-3 text-center">
            No data found for{" "}
            <span className="text-[#c45500] font-medium">"{storedString}"</span>
          </h5>
        </div>
        <button className="bg-[#ffffff] hover:bg-[#003459] border-[1px] border-[#003459] transition-all rounded-md">
          <Link
            to="/"
            className=" text-[#003459] hover:text-[#ffffff] font-medium hover:no-underline p-3"
          >
            Go Home
          </Link>
        </button>
      </div>
    </>
  ) : (
    <>
      <div className="mb-12 py-2 bg-[#ffff] border-b-[1px] border-[#d8d8d8] shadow-sm">
        <p className="mb-0 mx-auto 2xl:px-20 xl:px-[3.75rem] sm:px-14 lg:px-16">
          Your results for{" "}
          <span className=" text-[#c45500] font-medium">"{storedString}"</span>
        </p>
      </div>
      <div className=" grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 w-[90%] mx-auto row__gap gap-6">
        {storedSearchResult?.map((poster, i) => {
          return (
            <>
              <div className="flex flex-col hover:shadow-sm hover:rounded-md">
                <Link
                  to={`/${linkname}/${poster?._id}`}
                  className="cursor-pointer"
                >
                  <div className=" flex flex-col items-center justify-center">
                    <div className="w-48 2xl:w-60 flex justify-center items-center h-36 2xl:h-60">
                      <LazyLoadImage
                        className=" w-28 sm:w-36 max-h-[11rem] 2xl:max-h-[15rem] object-contain img"
                        src={
                          poster?.imgUrl[0] ||
                          poster?.imgUrl[1] ||
                          poster?.imgUrl[2]
                        }
                        alt="img"
                        effect="blur"
                        key={i}
                      />
                    </div>
                  </div>
                </Link>
                <div
                  className={`${
                    posters ? "mt-3 2xl:mt-1" : "mt-1"
                  } flex flex-row justify-evenly items-center`}
                >
                  <Link>
                    <p className="mb-0 text-blue-500">
                      {poster?.name?.split("|")[2]?.slice(3)
                        ? poster?.name?.split("|")[2]?.slice(3)
                        : poster?.name?.split("|")[2]
                        ? poster?.name?.split("|")[2]
                        : poster?.name?.split("|")[1]}
                    </p>
                  </Link>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {storedSearchResult?.length >= 15 ? (
        <div
          className={`${
            fullData
              ? "hidden"
              : "flex justify-center items-center w-[100px] mt-6 mx-auto cursor-pointer"
          }`}
          onClick={() => {
            setFullData(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <div className=" bg-[#003459] px-3 py-2 rounded-lg">
            <p className="mb-0 text-[#ffffff] font-medium whitespace-nowrap">
              See all
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SearchedProducts;
